"use strict";
exports.__esModule = true;
exports.initSocketEvents = function (client, store) {
    var currentLocationIds = ['2131']; // TODO change to real stores
    var updateTaskList = function (completedTasks) {
        store.dispatch({
            type: "SORT_TASKS",
            data: completedTasks
        });
    };
    var updateActionList = function (actionWithMetrics) {
        store.dispatch({
            type: "SET_ACTIONS",
            data: actionWithMetrics.actions
        });
        store.dispatch({
            type: "SET_ACTION_METRICS",
            data: actionWithMetrics.actionMetrics
        });
    };
    currentLocationIds.forEach(function (locationId) {
        client.webSocketConnection.on("location:" + locationId, "TaskModification", updateTaskList);
        client.webSocketConnection.on("location:" + locationId, "ActionModification", updateActionList);
    });
};
