import { put, takeLatest } from "redux-saga/effects";
import _ from "lodash";
import { history } from "react-scaffold/compiled/src";

import { fetchOpsArticle } from "lib-api/src/articles";

import { guidesPath } from "../paths";

function* checkRoute() {
  // Set new routing in the store to trigger rerender in connected components
  yield put({
    type: "SET_CURRENT_ROUTE",
    content: { activeLocation: history.location, action: history.action }
  });

  // Get URL
  const path = history.location.pathname;
  // Check if we are in the article view
  const guidePath = `${guidesPath}/`;
  if (path.includes(guidePath)) {
    // If so, get the last segment of path, which is the article slug
    const slug = _.last(path.split("/"));
    // Fetch article and set object and content headings in store
    const activeArticle = yield fetchOpsArticle(slug);
    const headings = activeArticle.content.filter(
      block => block.type === "heading"
    );
    yield put({ type: "SET_ACTIVE_ARTICLE", content: activeArticle });
    yield put({ type: "SET_TABLE_OF_CONTENTS", content: headings });
  } else {
    // Otherwise clear the store article related objects
    yield put({ type: "CLEAR_ACTIVE_ARTICLE" });
  }
}

function* sagas() {
  yield [takeLatest(["ROUTE_CHANGE"], checkRoute)];
}

export default sagas;
