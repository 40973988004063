import "../index.html";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";

import { initSocketEvents } from "./sockets";

// HACK - global import
// eslint-disable-next-line no-unused-vars
import theme from "spg-themes/compiled/main.scss";

// Scaffold elements
import { init, history } from "react-scaffold/compiled/src";
import { store } from "react-scaffold/compiled/src/store";
import RequireSession from "react-scaffold/compiled/src/components/RequireSession";

import { zamClient,
  initSingleton as initClient,
} from "zam-api/compiled/src/zamClient";

import ManagerLoginScreen from "rc-zambrero/compiled/src/components/LoginScreens";
import MainView from "spg-themes/compiled/src/components/MainView";

import config from "./config";
import routes from "./routes";

if (!env.servicesUrl) {
  throw new Error("env.servicesUrl missing");
}

// kick off the polyfill!
smoothscroll.polyfill();

// create a client
initClient(env.servicesUrl);

init(config, zamClient).then(() => {
  // Listen to route changes and dispatch action for opsArticles saga
  history.listen(() => store.dispatch({ type: "ROUTE_CHANGE" }));

  // initialise socket events
  initSocketEvents(zamClient, store);
  
  // Above doesn't work with page refresh or page load from given URL
  // so we'll check for initial load
  store.dispatch({ type: "ROUTE_CHANGE" });


  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route
            path="/logout"
            render={() => {
              zamClient.auth
                .logout()
                .then(() => history.push("/"))
                .catch(console.error);
              return <div>Logging out...</div>;
            }}
          />
          <Route
            path="/"
            render={() => (
              <React.Fragment>
                <RequireSession
                  session={true}
                  fallback={ManagerLoginScreen}
                >
                  <div>
                    <MainView routes={routes} />
                  </div>
                </RequireSession>
              </React.Fragment>
            )}
          />
        </Switch>
      </Router>
    </Provider>,
    document.getElementById("app")
  );
});




