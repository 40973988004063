import React from "react";

import HomeView from "./components/HomeView";


import { OpsArticlesView } from "rc-containers/compiled/src/components/OpsArticlesView";
import { OpsArticlesSideBar } from "rc-containers/compiled/src/components/OpsArticlesSideBar";

import { OpsArticleView } from "rc-containers/compiled/src/components/OpsArticleView";
import { OpsArticleSideBarPrefab } from "rc-containers/compiled/src/components/OpsArticleSideBarPrefab";

import { OpsResourcesView } from "rc-containers/compiled/src/components/OpsResourcesView";
import { OpsResourcesSideBar } from "rc-containers/compiled/src/components/OpsResourcesSideBar";

import TasksList from "rc-containers/compiled/src/components/TasksList";
import { TasksListMenu } from "rc-containers/compiled/src/components/TasksListMenu";
import ActionsList from "rc-containers/compiled/src/components/ActionsList";

import { tasksPath, resourcesPath, articlesPath } from "./paths";

const Home = () => <HomeView guidesPath={articlesPath} tasksPath={tasksPath} resourcesPath={resourcesPath} />;
const DailyTasks = () => <TasksList taskPeroid="daily" />;
const WeeklyTasks = () => <TasksList taskPeroid="weekly" />;
const MonthlyTasks = () => <TasksList taskPeroid="monthly" />;

const routes = [
  {
    icon: "home",
    label: "Home",
    path: "/",
    component: Home
  },
  {
    icon: "resources",
    label: "Guides",
    path: articlesPath,
    routes: [
      {
        path: "/",
        menu: OpsArticlesSideBar,
        component: OpsArticlesView
      },
      {
        path: "/:slug",
        component: OpsArticleView,
        menu: OpsArticleSideBarPrefab
      }
    ]
  },

  {
    icon: "download-solid",
    label: "Resources",
    path: resourcesPath,
    menu: OpsResourcesSideBar,
    component: OpsResourcesView
  }
];

if (env.showTasks === "true") {
  const tasksRoute = {
    icon: "task-solid",
    label: "Tasks",
    path: tasksPath,
    menu: TasksListMenu,
    routes: [
      {
        path: "/",
        component: DailyTasks
      },
      {
        path: "/weekly",
        component: WeeklyTasks
      },
      {
        path: "/monthly",
        component: MonthlyTasks
      },
      {
        path: "/issues",
        component: ActionsList
      },
    ]
  };

  routes.splice(1, 0, tasksRoute);
}

export default routes;


