import React from "react";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import Page, { PageHeader, PageTitle } from "spg-themes/compiled/src/components/Page";

import style from "./styles";

const tasksImgUrl =
  "https://dph95f73vdxmz.cloudfront.net/uploads/managers-app-fridge.jpg";

const guidesImgUrl =
  "https://dph95f73vdxmz.cloudfront.net/uploads/managers-app-food.jpg";
/**
 * Manager app homepage/portal view
 */
const HomeView = ({ guidesPath, tasksPath, resourcesPath }) => (
  <Page>
    <div className={style.home}>
      <div className={style.home_header}>
        <PageHeader>
          <PageTitle>Zambrero Portal</PageTitle>
        </PageHeader>
      </div>

      <div className={style.home_content}>
        {env.showTasks === "true" ?
          <NavLink className={[style.link, style.tasks].join(" ")} to={tasksPath}>
            <div
              style={{ backgroundImage: `url(${tasksImgUrl})` }}
              className={style.link_background}
            />
            <div className={style.link_body}>
              <header>
                <h2>Tasks</h2>
                <hr />
              </header>
              <p>Check out your tasks for today</p>
            </div>
          </NavLink>
          :
          <NavLink className={[style.link, style.tasks].join(" ")} to={resourcesPath}>
            <div
              style={{ backgroundImage: `url(${tasksImgUrl})` }}
              className={style.link_background}
            />
            <div className={style.link_body}>
              <header>
                <h2>Resources</h2>
                <hr />
              </header>
              <p>Useful resources for how to run your store</p>
            </div>
          </NavLink>
        }



        <NavLink
          className={[style.link, style.guides].join(" ")}
          to={guidesPath}
        >
          <div
            style={{ backgroundImage: `url(${guidesImgUrl})` }}
            className={style.link_background}
          />
          <div className={style.link_body}>
            <header>
              <h2>Guides</h2>
              <hr />
            </header>
            <p>Useful guides for how to run your store</p>
          </div>
        </NavLink>
      </div>
    </div>
  </Page>
);

export default withRouter(HomeView);

HomeView.propTypes = {
  /** Route to guides */
  guidesPath: PropTypes.string.isRequired,
  /** Route to tasks */
  tasksPath: PropTypes.string.isRequired,
  /** Route to resourcesPath */
  resourcesPath: PropTypes.string.isRequired,
};