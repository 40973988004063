// Reducers
import app from "react-scaffold/compiled/src/reducers/app";
import session from "react-scaffold/compiled/src/reducers/session";
import { scrollSync } from "spg-themes/compiled/src/reducers/scrollSync";
// Local
import routing from "./reducers/routing";
import auth from "./reducers/auth";

// Local
import routingSaga from "./sagas/routing";

import tasksReducer from "rc-containers/compiled/src/reducers/tasksReducer";
import tasksSaga from "rc-containers/compiled/src/sagas/tasksSaga";

import actionsReducer from "rc-containers/compiled/src/reducers/actionsReducer";
import actionsSaga  from "rc-containers/compiled/src/sagas/actionsSaga";

import opsResourcesSaga from "rc-containers/compiled/src/sagas/opsResources";
import opsResources from "rc-containers/compiled/src/reducers/opsResources";

import opsArticlesSaga from "rc-containers/compiled/src/sagas/opsArticles";
import opsArticles from "rc-containers/compiled/src/reducers/opsArticles";
import opsArticle from "rc-containers/compiled/src/reducers/opsArticle";

export default {
  debug: !!env.DEBUG,
  reducers: {
    app,
    auth,
    session,
    routing,
    actionsReducer,
    opsArticle,
    opsArticles,
    opsResources,
    tasksReducer,
    scrollSync
  },
  sagas: [routingSaga, opsResourcesSaga, opsArticlesSaga, tasksSaga, actionsSaga]
};
